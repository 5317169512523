import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 2rem 0;
  border-bottom: 1px solid white;
  .cell-image {
    width: 10rem;
  }
  .cell-title {
    width: calc(100% - 45rem);
  }
  .cell-subtotal {
    width: 10rem;
  }
  .cell-quantity {
    width: 10rem;
  }
  .cell-total {
    width: 10rem;
  }
  .cell-remove {
    width: 5rem;
    text-align: right;
  }
`

export const Button = styled.button`
  background-color: transparent;
  color: white;
  font-size: 2.4rem;
  text-transform: uppercase;
  cursor: pointer;
  border: 0;
  &:focus,
  &:hover {
    outline: 0;
  }
`
