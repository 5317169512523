import React, { useContext } from 'react'
import { Link } from 'gatsby'

import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import StoreContext from '~/context/StoreContext'
import { Wrapper, Button } from './styles'

const LineItem = props => {
  const { item } = props
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="60px"
    />
  ) : null

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(
        option => `${option.name}: ${option.value} `
      )
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id)
    toast.configure()
    toast.dark('Removed from cart', {
      position: 'bottom-right',
      autoClose: 3000,
      transition: Slide,
    })
  }

  return (
    <Wrapper>
      <div className="cell-image">
        <Link to={`/product/${item.variant.product.handle}/`}>
          {variantImage}
        </Link>
      </div>
      <div className="cell-title">
        <p>
          {item.title}
          {`  `}
          {item.variant.title === !'Default Title' ? item.variant.title : ''}
          <br />
          {selectedOptions}
        </p>
      </div>
      <div className="cell-subtotal">{item.variant.price} €</div>
      <div className="cell-quantity">
        {/* {item.quantity > 1 ? `x${item.quantity}` : ''} */}x{item.quantity}
      </div>
      <div className="cell-total">{item.variant.price * item.quantity} €</div>
      <div className="cell-remove">
        <Button onClick={handleRemove}>&times;</Button>
      </div>
    </Wrapper>
  )
}

export default LineItem
