import React from 'react'
import SEO from '../components/seo'

import Cart from '~/components/Cart'
import { Container } from '~/utils/styles'

const CartPage = () => (
  <Container>
    <SEO title="Cart" />
    <Cart />
  </Container>
)

export default CartPage
