import styled from '@emotion/styled'

import { breakpoints } from '../../utils/styles'

export const TotalRows = styled.ul`
  list-style: none;
  padding: 0;
  margin: 6rem 0;
  width: 35rem;
  margin-left: auto;
  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    &:last-child {
      font-weight: bold;
    }
  }
  h2,
  p {
    margin: 0;
    font-size: 2rem;
  }
  h2 {
    width: 20rem;
  }
  p {
    width: 15rem;
  }
`

export const CheckboxWrap = styled.div`
  max-width: 35rem;
  margin-left: auto;
  margin-bottom: 3rem;
  a {
    text-decoration: underline;
  }
`

export const Button = styled.button`
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1.6rem 2rem;
  cursor: pointer;
  width: 35rem;
  margin-left: auto;
  display: block;
  &:hover:not([disabled]) {
    background: white;
    color: black;
  }
  &:focus,
  &:hover {
    outline: 0;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
