import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'gatsby'

import { css } from '@emotion/react'
import BounceLoader from 'react-spinners/BounceLoader'

import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'

import { Button, TotalRows, CheckboxWrap } from './styles'
import { Wrapper } from './LineItem/styles'

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const [loaded, setLoaded] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)

  useEffect(() => {
    console.log(checkout.lineItems.length)

    setTimeout(() => {
      setLoaded(true)
    }, 1000)

    // const length = checkout.lineItems.length
    // if (length !== cartNumber && length > 0) {
    //   console.log('activate cart')
    //   setCartNumber(length)
    // } else if (length > 0) {
    //   console.log('activate cart')
    // } else {
    //   console.log('activate message')
    // }
  }, [])

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  return (
    <div style={{ margin: '10rem 0 15rem' }}>
      {!loaded && (
        <BounceLoader
          // css={override}
          size={40}
          color={'white'}
          css={{ margin: '0 auto' }}
          // loading={this.state.loading}
        />
      )}
      {loaded && checkout.lineItems.length > 0 && (
        <>
          <Wrapper>
            <div className="cell-image"></div>
            <div className="cell-title">Product</div>
            <div className="cell-subtotal">Subtotal</div>
            <div className="cell-quantity">Quantity</div>
            <div className="cell-total">Total</div>
            <div className="cell-remove"></div>
          </Wrapper>
          {lineItems}
          <TotalRows>
            {/* <li>
            <h2>Subtotal</h2>
            <p>{checkout.subtotalPrice} €</p>
          </li> */}
            {/* <li>
            <h2>Taxes</h2>
            <p>{checkout.totalTax} €</p>
          </li> */}
            <li>
              <h2>Total</h2>
              <p>{checkout.totalPrice} €</p>
            </li>
          </TotalRows>
        </>
      )}
      {loaded && checkout.lineItems.length > 0 && (
        <>
          <CheckboxWrap>
            <label>
              <input
                type="checkbox"
                onClick={() => setTermsAccepted(!termsAccepted)}
                checked={termsAccepted}
              />{' '}
              I accept <Link to="/terms-conditions">terms and conditions</Link>
            </label>
          </CheckboxWrap>
          <Button
            onClick={handleCheckout}
            disabled={checkout.lineItems.length === 0 || !termsAccepted}
          >
            Checkout
          </Button>
        </>
      )}
      {loaded && checkout.lineItems.length === 0 && (
        <p style={{ textAlign: 'center' }}>Your cart is empty</p>
      )}
    </div>
  )
}

export default Cart
